<template>
    <div class="item" :class="{supply: item.type === 2}">
        <div class="head">
            <h2 class="title">{{item.title}}</h2>
            <span class="text">{{item.type === 1 ? '采购数量' : '供应数量'}}：<span>{{item.num}}</span> {{item.unitName}}</span>
            <span class="text">{{item.type === 1 ? '采购方' : '供应方'}}：{{item.companyName}}</span>
        </div>
        <div class="center">
            <span class="text">发布时间：{{item.createdAt}}</span>
            <span class="text">商品名称：{{item.goodName}}</span>
            <div class="line">
                <span class="text">价格说明：<span>¥ {{item.price}}</span>/{{item.unitName}}</span>
                <span class="tag" v-if="item.priceStatus === 1">可议价</span>
                <span class="tag" v-if="item.priceStatus === 2">不可议价</span>
                <span class="tag" v-if="item.priceStatus === 3">面议</span>
            </div>
            <div class="textMulti">
                <span class="text">详细说明：</span>
                <span class="desc">{{item.content}}</span>
            </div>
        </div>
        <div class="footer" v-if="item.type === 1">
            <div class="info">
                <span class="label">已有报价：<span>{{item.quotedNum}}</span> 条</span>
                <span class="label">距离报价结束<span> {{item.diffDay}} </span>天</span>
            </div>

            <el-button plain type="primary" :disabled="!isLogin" @click="$router.push({path: '/shop/buying/detail', query: {id: item.id, companyId: item.companyId, companyName: item.companyName}})">立即报价</el-button>
        </div>

        <div class="footer" v-if="item.type === 2">
            <span class="label" v-if="item.diffDay > 0">距离出售<span>{{item.diffDay}}</span>天</span>
            <span class="status" v-else>出售中</span>

            <el-button plain type="primary"  @click="$router.push({path: '/shop/supply/detail', query: {id: item.id, companyId: item.companyId, companyName: item.companyName}})">详情</el-button>
        </div>
    </div>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: "infoItem",
    props: {
      item: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    computed: {
      ...mapState({
        isLogin: state => state.user.isLogin,
      })
    },
  }
</script>
